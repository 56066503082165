@import '~@travel-ui/styles/src/utility';

.banner {
  display: block;
  width: 100%;
  line-height: 0;

  .media {
    width: 100%;
  }
}
