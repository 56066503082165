@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

.wrapper,
.notFixedPc {
  width: 100%;
  flex-shrink: 0;
  z-index: $z-index-header;

  @include media(sp) {
    &.absolute {
      position: absolute;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateY(0px);
      transition: 0.2s;
      will-change: transform;

      &.fixedHidden {
        transform: translateY(-$traveler-header-height-sp);
      }
    }
  }
}

.wrapper {
  @include media(tl, pc) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.container {
  border-bottom: 1px solid $isabelline;
  background: $white;
  display: block;

  @include media(sp) {
    height: 60px;
  }

  &.transparent {
    border-bottom: none;
    background: rgba(0, 0, 0, 0.3);
    color: $white;
  }

  .menuBar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $spacing-base;

    @include media(tl) {
      padding: $spacing-m;
    }

    @include media(pc) {
      @include pc-layout;
      padding-top: $spacing-m;
      padding-bottom: $spacing-m;
    }

    .actionWrapper {
      display: flex;
      align-items: center;
    }
  }
}

.disable {
  cursor: none;
  pointer-events: none;
}

.menuButton {
  @include resetButtonStyle;
  @include box(32px);

  align-self: center;
  padding: 5px;

  &.active {
    position: relative;

    &::after {
      @include box(12px);
      position: absolute;
      content: ' ';
      display: block;
      background: $scarlet;
      top: $spacing-s;
      right: 0;
      border-radius: 50%;
    }
  }
}

.menuDialog {
  background: $white;
  position: fixed;
  z-index: $z-index-header - 1;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  overflow: auto;

  @include media(sp) {
    width: 100%;
  }

  @include media(tl, pc) {
    z-index: $z-index-header + 1;
  }

  .closeButton {
    position: absolute;
    top: $spacing-m;
    left: $spacing-base;
  }
}

.menuBar .accessible {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.notificationIcon.transparent {
  path:last-child {
    fill: $white;
  }
}

@mixin container-animate($is-open) {
  transition: transform 0.7s;

  @if $is-open {
    transform: translateY(0);
  } @else {
    transform: translateY(-100%);
  }
}

.headerAnimate {
  &Enter {
    @include container-animate(false);
  }

  &EnterActive {
    @include container-animate(true);
  }

  &Exit {
    @include container-animate(true);
  }

  &ExitActive {
    @include container-animate(false);
  }
}

.menuOverlay {
  @include resetButtonStyle;
  @include box(100vw, 100vh);
  background: $black;
  opacity: 0.8;
  position: fixed;
  left: 0;
  z-index: $z-index-header - 2;
  top: 0;

  @include media(tl, pc) {
    z-index: $z-index-header;
  }

  &.dialogOverlay {
    background: $black;
    opacity: 0;
    transition: opacity 1s;
  }
}

@mixin menu-animate($is-open) {
  @include move_long(all);

  @if $is-open {
    transform: translateX(0);
    opacity: 1;
  } @else {
    transform: translateX(100%);
    opacity: 0;
  }
}

.menuDialogAnimate {
  &Enter {
    @include menu-animate(false);
    transition: none;
  }

  &EnterActive {
    @include menu-animate(true);
  }

  &Exit {
    @include menu-animate(true);
  }

  &ExitActive {
    @include menu-animate(false);
  }
}

// Header content
.contentWrapper {
  padding-top: 60px;
  color: $jet;

  .linkWrapper {
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
  }

  .linkRow {
    @include resetButtonStyle;
    display: flex;
    width: 100%;
    padding: 12px $spacing-m;
    color: $jet;
    align-items: center;
    text-decoration: none;

    .iconText {
      span:first-child > :first-child {
        color: $jet;
      }
    }

    &:last-child {
      padding: $spacing-l $spacing-m;
      border-top: 1px solid $isabelline;
    }

    &:hover {
      background: $shady-white;
    }

    .userPreference {
      margin-left: auto;
    }
  }

  .menuLoginInfo {
    background: $shady-white;
    padding: $spacing-m;
    align-items: flex-start;

    .name {
      font-size: $font-uranus;
      font-weight: bold;
      line-height: 1.25;
      @include text-truncate();
    }

    .point {
      @include font-body;

      &:not(:first-child) {
        margin-top: $spacing-base;

        @include media(sp) {
          margin-top: $spacing-s;
        }
      }
    }
  }
}

.contentWithBanner {
  padding-top: 120px;
}

.optionsBox {
  margin: 0 $spacing-l 0 auto;

  @include media(tl) {
    margin: 0 0 0 auto;
  }

  align-self: flex-start;
}

.selectIconTxt {
  @include caption-12;

  & > span {
    margin-right: 5px;
  }
}

.logo {
  line-height: 0;
  padding-top: 6px;

  @include media(sp) {
    line-height: initial;
    padding-top: 0;
    outline: none;
  }
}

.loginInfo {
  flex-direction: column;
  font-size: $font-mars;
  display: flex;
  padding-right: $spacing-base;
  align-items: flex-end;

  &.invisible {
    opacity: 0;
  }

  .text {
    max-width: 270px;
    font-weight: bold;
    text-align: right;
    display: block;
    @include text-truncate();
  }

  .icon {
    svg {
      min-height: 0px;

      g > g {
        fill: $apple-red;
      }
    }
  }
}

// Wide banner
.wideBanner {
  position: relative;
  z-index: 1;
}
