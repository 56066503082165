@import '~@travel-ui/styles/src/utility';

.smartBanner {
  animation: jump 1s;
  background: #fff;
  display: inline-flex;
  align-items: center;
  height: 60px;
  padding: 0 $spacing-08;
  position: relative;
  width: 100%;
}

@keyframes jump {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0);
  }
}

.contentContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.removed {
  display: none;
}

.meta {
  margin: 0 $spacing-08;
}

.title {
  @include font-pc-body-b2;
  color: $black;
}

.storeName {
  @include font-pc-captions-c1;
  color: $sonic-silver;
}

.icon {
  margin: 0 $spacing-12;
  vertical-align: top;
  width: $spacing-48;
}

.close {
  margin-right: $spacing-08;
  width: $spacing-16;
}

.viewLink {
  @include font-pc-body-b2;
  color: $blue-raspberry;
  text-align: end;
  text-decoration: none;
}
