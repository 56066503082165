@import '~@travel-ui/styles/src/utility';
// Select Popup
$popup-pointer-size: 15px;

.selectPopup {
  position: relative;

  .label {
    cursor: pointer;
  }

  .popup {
    color: $jet;
    transform: translateX(-70%);
    width: 50vw;
    max-width: 500px;

    &::after {
      @include box($popup-pointer-size);
      content: ' ';
      display: block;
      position: absolute;
      top: -#{$popup-pointer-size};
      left: 75%;
      border-top: calc($popup-pointer-size / 2) solid transparent;
      border-right: calc($popup-pointer-size / 2) solid transparent;
      border-bottom: calc($popup-pointer-size / 2) solid $white;
      border-left: calc($popup-pointer-size / 2) solid transparent;
    }

    &.middle {
      transform: translateX(-50%);

      &::after {
        left: 50%;
      }
    }

    .optionList {
      display: flex;
      flex-wrap: wrap;
    }

    .optionItem {
      cursor: pointer;
      width: calc(100% / 3);
      flex-shrink: 0;
      padding: $spacing-base;
      font-size: 11px;
      display: flex;
      align-items: center;

      .optionTitle {
        text-transform: uppercase;
        font-weight: bold;
        margin-right: $spacing-s;
      }

      &:hover {
        background: $shady-white;
      }

      &.active {
        background: $cilantro;
        color: $white;
      }
    }
  }
}

.dropDownOpen {
  transform: rotateX(180deg);
  transition: transform 0.2s linear;
}

.dropDownClose {
  transform: rotateX(0deg);
  transition: transform 0.2s linear;
}
