@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

$max-banner-height: 60px;
$top-empty-bottom-spacing: 56px; // actual padding + spacing for image slider dots
$top-empty-spacing--tl: $spacing-24 + $max-banner-height;
$top-empty-spacing--pc: 40px + $max-banner-height;
$area-empty-spacing--tl: $traveler-header-height-sp;
$area-empty-spacing--pc: $traveler-header-height-pc;
$area-image-height--pc: 385px;
$top-image-height--pc: 478px;

.container {
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  width: 100%;

  @include media(tl, sp) {
    position: relative;
    height: 30vh;
  }

  @include media(pc) {
    height: 50vh;
  }

  @media (min-width: 1921px) {
    height: 550px;
  }
}

.mediaWrapper {
  width: 100%;
  position: relative;
}

.media {
  &#{&} {
    -o-object-position: center 40%;
    object-position: center 40%;
  }
}

.withSmartBanner {
  margin-top: 60px;
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @include media(sp) {
    // same overlay as apps
    background: rgba(51, 51, 51, 0.25);
  }

  @include media(tl, pc) {
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.4), rgba(51, 51, 51, 0) 50%);
  }
}
