@import '~@travel-ui/styles/src/utility';
@import '../heroImage.module.scss';

.imageSlider {
  @include box(100%);

  @include media(pc) {
    height: $top-image-height--pc;
  }

  top: 0;
  left: 0;
  position: absolute;
}

.mediaWrapper {
  height: 100%;
}
