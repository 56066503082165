@import '~@travel-ui/styles/src/utility';

.betaWrapper {
  flex: 1 0;
  padding-left: $spacing-16;
  display: flex;
  position: relative;

  @include media(sp) {
    padding-left: 0;
    padding-right: $spacing-16;
    flex-direction: row-reverse;
  }

  .beta {
    @include resetButtonStyle;
    background-color: $sonic-silver;
    font-weight: bold;
    padding: 3px $spacing-08;
    border-radius: $spacing-04;
    min-width: 47px;
    color: $white;
    z-index: $z-index-header;
    user-select: none;
  }

  .popup {
    background: $shady-white;
    opacity: 0.8;
    padding: 12px $spacing-16;
    line-height: $line-height-sub-title;
    border-radius: $spacing-08;
    width: 240px;
    font-size: $font-mars;
    top: 120%;
    left: $spacing-16;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16),
      0px 3px 1px rgba(0, 0, 0, 0.1);
  }

  @mixin fade-out($is-fade) {
    transition: opacity 0.3s cubic-bezier(0.75, 0, 0.6, 1);

    @if $is-fade {
      opacity: 0;
    } @else {
      opacity: 0.8;
    }
  }

  .popupDialog {
    &Enter {
      @include fade-out(false);
    }

    &EnterActive {
      @include fade-out(false);
    }

    &Exit {
      @include fade-out(true);
    }

    &ExitActive {
      @include fade-out(true);
    }
  }

  .popupText {
    color: $jet;
    line-height: $line-height-sub-title;
    user-select: none;

    a {
      color: $cilantro;
      cursor: pointer;

      &:hover {
        color: $lincoln-green;
      }
    }
  }
}
